<template>
  <VModalWidget :submit="submit" :dismiss="dismiss" :submitting="submitting">
    <template v-slot:title>{{ $t('title') }}</template>

    <template v-slot:content>
      <FormErrors :show="showResourceErrors" :errors="resourceErrors" />
      <v-form>
        <v-container grid-list-md class="px-0 py-0">
          <v-row>
            <v-col cols="12">
              <v-select
                v-model="form.value"
                :items="options"
                :label="$t('labels.decision')"
                item-text="display"
                item-value="value"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </template>
  </VModalWidget>
</template>

<script>
import pick from 'lodash/pick'
import { VModalWidget, BaseForm, FormErrors } from '@argon/app/components'
import { Modal } from '@argon/app/mixins'

export default {
  name: 'DecisionModal',
  extends: BaseForm,
  mixins: [Modal],
  components: {
    VModalWidget,
    FormErrors
  },
  props: {
    entityId: { type: [String, Number], required: true },
    clientId: { type: [String, Number], required: true }
  },
  validations: {
    form: {}
  },
  data() {
    return {
      form: {
        value: null
      },
      options: [
        { value: 'accepted', display: this.$t('labels.accepted') },
        { value: 'refused', display: this.$t('labels.refused') },
        { value: 'overruled', display: this.$t('labels.overruled') }
      ],
      submitting: false
    }
  },
  computed: {},
  methods: {
    submit() {
      if (this.$v.form.$invalid) {
        return this.$v.form.$touch()
      }
      this.submitting = true
      this.dismiss()
    }
  }
}
</script>

<i18n>
{
  "en": {
    "title": "Application Decision"
  },
  "fr": {
    "title": "Décision de l'application"
  }
}
</i18n>
